<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'AccountGroup'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/chartofaccount`" title="Account Group"
                :columns="columns" routerpath="/master/account" :formOptions="formOptions" :edit="edit" :add="add"
                :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import { BRow, BCol } from "bootstrap-vue";

import TabPosition from "../../../../components/TabView.vue";
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
    BRow,
    BCol,
  },
  data() {
    return {
      // tabs: [
      //   { name: 'Ledger', route: '/master/accounting/ledger', icon: 'WindIcon' },
      //   { name: 'AccountGroup', route: '/master/accounting/accountgroup', icon: 'UsersIcon' },
      //   { name: 'BankAccountMaster', route: '/master/accounting/bankaccount', icon: 'GridIcon' },
      //   { name: 'BankStatement', route: '/master/accounting/bankstatement', icon: 'FileTextIcon' },
      //   { name: 'Vendor', route: '/master/accounting/vendor', icon: 'SidebarIcon' },
      //   { name: 'Vendortype', route: '/master/accounting/vendortype', icon: 'TypeIcon' },
      //   { name: 'Vendorcategory', route: '/master/accounting/vendorcategory', icon: 'OctagonIcon' },
      //   { name: 'Broker', route: '/master/accounting/broker', icon: 'UserIcon' },
      //   { name: 'Farmer', route: '/master/accounting/farmer', icon: 'FramerIcon' },
      //   { name: 'PurchaseBuyer', route: '/master/accounting/purchasebuyer', icon: 'TriangleIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.query.id ? "Edit Account Group" : "Add Account Group"
          }`,
        submitRouterPath: "/master/account/accountgroup",
        getEditValue: `${baseApi}/getchartofaccountById`,
        gridForm: true,
        method: "post",
        action: "add",
        url: `${baseApi}/chartofaccount`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Name",
            name: "name",
            value: "",
            type: "text",
            class: "col-md-12",
            placeholder: "Enter Name",
            required: true,
          },
          {
            label: "Parent",
            name: "parent",
            value: "",
            type: "dropdown",
            class: "col-md-12",
            dropdownname: "parent",
            url: "chartofaccount",
            responseValue: "id",
            required: false,
            options: [],

          },
        ],
      },
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search ID",
        //   },
        // hidden: true
        // },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Parent",
          field: "parent",
          type: "dropdown",
          url: "chartofaccount",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
         {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        // {
        //   label: "Parent",
        //   field: "parent_name",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search Parent",
        //   },
        // },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
      viewFields: [
              {
                label: 'Name',
                field: 'name',
                type: 'text',
                class: 'col-md-4'
              },
              {
                label: 'Parent',
                field: 'parent',
                type: 'dropdown',
                responsevalue: 'name',
                class: 'col-md-4'
              },
            ],
    };
  },
  beforeMount() {
    this.getDropdowns();
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Account Group") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.canDelete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
  methods: {
    async getDropdowns() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == "dropdown" && item.url) {
          const requestOptionsUsers = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            url: `${baseApi}/${item.url}`,
          };

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data);

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, "error"));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
